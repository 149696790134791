<template>
  <div class="wrapper">
    <WorkInProgress feature="Portals Overview" />
  </div>
</template>

<script>
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";

export default {
  name: "PortalsOverview",

  components: { WorkInProgress },

  data() {
    return {};
  },

  created() {
    this.$emit("click");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: calc(100vh - 208px);
}
</style>
